import React, { ReactNode } from "react";

import Head from "next/head";
import { Header } from "./Header";
import { Footer } from "./Footer";
import { Toaster } from "react-hot-toast";
import Script from "next/script";

type Props = {
  children?: ReactNode;
  title?: string;
};

const Layout = ({ children, title = "Golf Champs" }: Props) => (
  <div>
    <Head>
      <title>{title}</title>
      <meta charSet="utf-8" />
      <meta
        name="viewport"
        content="initial-scale=1.0, width=device-width, user-scalable=no"
      />
    </Head>
    <Script src="https://securepubads.g.doubleclick.net/tag/js/gpt.js" />
    <div className="flex flex-col min-h-screen justify-between">
      <Header />
      <Toaster />
      <div className="mb-auto">{children}</div>
      <Footer />
    </div>
  </div>
);

export default Layout;
