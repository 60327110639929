import { v4 as uuid } from "uuid";

const fetchWithRequestId = (url, config) => {
  const requestId: string = uuid();
  // console.log("Config headers are ", config.headers);
  config.headers["x-request-id"] = requestId;
  return fetch(url, config);
};

export default fetchWithRequestId;
