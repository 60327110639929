import Link from "next/link";
import Image from "next/image";
import React from "react";
import app_store_badge from "../../public/app_store_badge.svg";
import google_app_store_badge from "../../public/google-play-badge.png";

interface Props {}

export const Footer = (props: Props) => {
  return (
    <div>
      <div className="px-3 pt-3 grid grid-cols-1 md:grid-cols-4 bg-black text-white font-serif">
        <div className="flex mb-5 md:mb-0 flex-col place-self-center justify-self-center">
          <div>
            <Link href={"mailto:michael@golfchamps.net"}>
              <a>
                <span className="hover:underline hover:cursor-pointer">
                  Click here to advertise
                </span>
              </a>
            </Link>
          </div>
        </div>
        <div className="flex flex-col mb-5 md:mb-0 place-self-center justify-self-center">
          <div className="hover:cursor-pointer">
            <Link
              href={
                "https://play.google.com/store/apps/details?id=net.golfchamps.app&hl=en&gl=US"
              }
            >
              <a target="_blank">
                <div className="relative w-48">
                  <Image
                    alt="GolfChamps Google Play app"
                    layout="responsive"
                    src={google_app_store_badge}
                    unoptimized
                  />
                </div>
              </a>
            </Link>
          </div>
        </div>
        <div className="flex flex-col mb-5 md:mb-0 place-self-center justify-self-center">
          <div className="hover:cursor-pointer">
            <Link
              href={"https://apps.apple.com/za/app/golfchamps/id1376819307"}
            >
              <a target="_blank">
                <div className="relative w-40">
                  <Image
                    alt="GolfChamps Apple iOS app"
                    layout="responsive"
                    src={app_store_badge}
                  />
                </div>
              </a>
            </Link>
          </div>
        </div>
        <div className="flex flex-col place-self-center justify-self-center">
          <div className="text-center hover:cursor-pointer">
            <Link href={"/terms"}>
              <a>Connect with Us</a>
            </Link>
          </div>
          <div className="flex flex-row justify-around">
            <div>
              <Link href={"http://www.facebook.com/Golfchamps"}>
                <a target="_blank">
                  <span className="hover:cursor-pointer">
                    <Image
                      alt="Facebook"
                      width={30}
                      height={30}
                      src={"/icons8-facebook.svg"}
                    />
                  </span>
                </a>
              </Link>
              <Link href={"http://www.twitter.com/Golfchamps_"}>
                <a target="_blank">
                  <span className="hover:cursor-pointer">
                    <Image
                      alt="Twitter"
                      width={30}
                      height={30}
                      src={"/icons8-twitter.svg"}
                    />
                  </span>
                </a>
              </Link>
              <Link href={"https://www.instagram.com/golf_champs"}>
                <a target="_blank">
                  <span className="hover:cursor-pointer">
                    <Image
                      alt="Instagram"
                      width={30}
                      height={30}
                      src={"/icons8-instagram.svg"}
                    />
                  </span>
                </a>
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="text-center bg-black pb-2 font-serif text-white text-xs">
        <Link href={"/terms"}>
          <a>
            <span className="hover:underline hover:cursor-pointer">
              View our Terms and Conditions
            </span>
          </a>
        </Link>
        <br />
        &copy; GolfChamps, Pty Ltd
      </div>
    </div>
  );
};
